<template>
  <v-card id="cw-confirmation-dialog--dialog__application-unfinished">
    <v-card-title id="cw-confirmation-dialog__title" class="text-h5">
      <translate>
        Unfinished loan application
      </translate>
    </v-card-title>
    <v-card-text id="cw-confirmation-dialog__text">
      <p v-translate>
        Press 'Continue' to continue the pending application.
      </p>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn
        id="cw-confirmation-dialog__confirm"
        :loading="$wait.waiting('confirmation_dialog__confirm_processing')"
        :disabled="$wait.waiting('confirmation_dialog__confirm_processing')"
        color="primary"
        text
        @click="
          closeDialog();
          $eventLogger.clickEvent($event);
        "
      >
        <translate>
          Continue
        </translate>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'CwDialogApplicationUnfinished',

  computed: {
    ...mapGetters({
      hashId: 'application/hashId',
    }),
  },

  methods: {
    closeDialog() {
      this.$wait.start('confirmation_dialog__confirm_processing');
      this.$wait.start('SUBMIT_FORM');
      this.$bus.$emit('CLOSE_CONFIRMATION_DIALOG');
      this.redirectToExistingApplication(`/changePage/${this.hashId}`);
    },

    redirectToExistingApplication(path) {
      this.$router.replace(path);
    },
  },
};
</script>
